import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout/Layout'
import * as styles from './404NotFound.module.css'
import { rhythm, scale } from '../utils/typography'

const NotFoundPage = () => (
  <Layout>
    <article className={styles.page}>
      <h1>NOT FOUND (*ﾟﾛﾟ)</h1>
      <p>
        You just hit a route that doesn&#39;t exist... and I&#39;m terribly
        sorry about it because that is probably a fault of mine :(
      </p>
      <video className={styles.video} autoPlay="true" loop="true">
        <source src="/jaime-404.mp4" type="video/mp4" />
        <p>
          Your browser doesn't support HTML5 video. Here is a{' '}
          <a href="/jaime-404.mp4">link to the video</a> instead.
        </p>
      </video>
      <p>
        But don't lose yar patience yet! Take a look at{' '}
        <Link to="/archives">The Mighty Archives</Link> or the{' '}
        <Link to="/notes">obscure notes</Link> and you may find what your
        seeking.
      </p>
    </article>
  </Layout>
)

export default NotFoundPage
